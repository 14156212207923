import farms from "assets/svg/FarmWhite.svg";
import fields from "assets/svg/FieldsWhite.svg";
import trees from "assets/svg/Trees.svg";
import cropFields from "assets/svg/Crop_Fields.svg";
import pestNDiseases from "assets/svg/Pest_n_Diseases.svg";
import program from "assets/svg/Program.svg";
import inventories from "assets/svg/Inventories.svg";
import assets from "assets/svg/AssetsWhite.svg";
import sensors from "assets/svg/Sensors.svg";
import users from "assets/svg/Users.svg";
import labors from "assets/svg/Labors.svg";
import reportsNDashboard from "assets/svg/Reports_n_Dashboard.svg";
import learningZone from "assets/svg/Learning_Zone.svg";
import settings from "assets/svg/settings.svg";
import logout from "assets/svg/logout.svg";
import evaluation from "assets/svg/evaluation.svg";
/* import pivot from "assets/svg/pivot-sidebar.svg";
 */ import microscope from "assets/svg/microscope.svg";
import { authServices } from "services/auth.services";
export const listsWithTree = [
  {
    title: "regions",
    imgSrc: farms,
    path: "/farms",
  },
  {
    title: "farms",
    imgSrc: fields,
    path: "/fields",
  },
  /* {
    title: "sectors",
    imgSrc: pivot,
    path: "/sectors",
  }, */
  {
    title: "trees",
    imgSrc: trees,
    action: () => console.log("a"),
    path: "/trees",
  },

  /* {
    title: "cropFields",
    imgSrc: cropFields,
    action: () => console.log("a"),

    path: "/crop-fields",
  }, */
  {
    title: "traps",
    imgSrc: pestNDiseases,
    path: "/traps",
  },
  {
    title: "Lab Samples",
    imgSrc: microscope,
    path: "/labSamples",
  },
  {
    title: "work orders",
    imgSrc: program,
    path: "/programs",
  },
  {
    title: "inventories",
    imgSrc: inventories,
    action: () => console.log("a"),

    path: "/inventories",
  },
  /*  {
    title: "assets",
    imgSrc: assets,
    path: "/assets",
  }, */
  /*  {
    title: "sensors",
    imgSrc: sensors,
    path: "/sensors",
  }, */
  {
    title: "users",
    imgSrc: users,
    path: "/users",
  },
  /*  {
    title: "labors",
    imgSrc: labors,
    path: "/labors",
  }, */
  {
    title: "reportsAndDashboard",
    imgSrc: reportsNDashboard,
    action: () => console.log("a"),

    path: "/reports",
  },
  /*  {
    title: "learningZone",
    imgSrc: learningZone,
    action: () => console.log("a"),
    path: "/learning-zone",
  }, */
  {
    title: "evaluation",
    imgSrc: evaluation,
    action: () => console.log("a"),
    path: "/evaluation",
  },
  {
    title: "settings",
    imgSrc: settings,
    path: "/settings",
  },
  {
    title: "logout",
    imgSrc: logout,
    action: () => authServices.logout(),
  },
];
export const superAdminList = [
  {
    title: "evaluation",
    imgSrc: evaluation,
    action: () => console.log("a"),
    path: "/evaluation",
  },

  {
    title: "logout",
    imgSrc: logout,
    action: () => authServices.logout(),
  },
];
export const listsWithoutTree = [
  {
    title: "region",
    imgSrc: farms,
    path: "/farms",
  },
  {
    title: "farms",
    imgSrc: fields,
    path: "/fields",
  },
  /* {
    title: "sectors",
    imgSrc: pivot,
    path: "/sectors",
  }, */
  /* {
    title: "trees",
    imgSrc: trees,
    action: () => console.log("a"),
    path: "/trees",
  }, */
  {
    title: "cropFields",
    imgSrc: cropFields,
    action: () => console.log("a"),

    path: "/crop-fields",
  },
  {
    title: "pestAndDiseases",
    imgSrc: pestNDiseases,
    path: "/pest-diseases",
  },
  {
    title: "Lab Samples",
    imgSrc: microscope,
    path: "/labSamples",
  },
  {
    title: "program",
    imgSrc: program,
    path: "/programs",
  },
  {
    title: "inventories",
    imgSrc: inventories,
    action: () => console.log("a"),

    path: "/inventories",
  },
  {
    title: "assets",
    imgSrc: assets,
    path: "/assets",
  },
  {
    title: "sensors",
    imgSrc: sensors,
    path: "/sensors",
  },
  {
    title: "users",
    imgSrc: users,
    path: "/users",
  },
  {
    title: "labors",
    imgSrc: labors,
    path: "/labors",
  },
  {
    title: "reportsAndDashboard",
    imgSrc: reportsNDashboard,
    action: () => console.log("a"),

    path: "/reports",
  },
  {
    title: "learningZone",
    imgSrc: learningZone,
    action: () => console.log("a"),
    path: "/learning-zone",
  },
  {
    title: "settings",
    imgSrc: settings,
    path: "/settings",
  },
  {
    title: "logout",
    imgSrc: logout,
    action: () => authServices.logout(),
  },
];
